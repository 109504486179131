.swal2-styled.swal2-confirm {
  background-color: green !important;
}

.swal2-styled.swal2-cancel {
  background-color: #EBEDEF !important;
  color: #000 !important;
}

.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  z-index: 99999 !important;
}

#zmmtg-root {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent !important;
}

._loading_overlay_overlay {
  z-index: 88888;
  min-height: 100% !important;
}

.notistack-snackbar {
  z-index: 99999 !important;
}



.showcase_iframe {
  width: 100%;
  height: 100%;
  align-self: center;
  box-shadow: 0 4px 8px rgb(43 43 43);
  display: flex;
}

#color {
  width: 20px;
  height: 20px;
}

#type {
  width: 20px;
  height: 20px;
}

#goto {
  text-align: center;
  width: 20px;
  height: 20px;
}

#icon {
  text-align: center;
  width: 20px;
  height: 20px;
}

#hideShow {
  text-align: center;
  width: 20px;
  height: 20px;
}

#color div {
  width: 13px;
  height: 13px;
  margin-left: auto;
  margin-right: auto;
}

#type div {
  width: 13px;
  height: 13px;
  margin-left: auto;
  margin-right: auto;
}

#goto div:after {
  display: inline-block;
  content: "\2197";
  font-size: 16pt;
  color: rgb(0, 0, 161);
  text-align: center;
}

#icon div:after {
  display: inline-block;
  content: "\00d7";
  font-size: 16pt;
  color: rgb(161, 0, 0);
}

/* #hideShow div:after {
    display: inline-block;
    content: "\00d7";
    font-size: 16pt;
    color: rgb(161, 0, 0);
  } */

.model_button span {
  font-size: 1vw;
  white-space: initial;
  text-align: center;
}

.scrollable input {
  width: 50%;
  height: 100%;
  border: 1px solid rgb(53, 53, 53);
  margin-top: auto;
  padding: 5px;
  background-color: #eee;
  color: black;
  font-size: 12pt;
  align-self: center;
}

.scrollable td {
  border: 1px solid rgb(53, 53, 53);
  display: table-cell;
  vertical-align: inherit;
}


#label {
  width: 35%;
}

._loading_overlay_wrapper {
  height: 100% !important;
}

.css-37q2kq {
  background: #346494 !important;
}



/* 
  
  
  
  ///////////////
  
  */


.showcase_container {
  height: 63vh;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  flex-direction: column;
}



.showcase_iframe {
  width: 100%;
  height: 100%;
  align-self: center;
  box-shadow: 0 4px 8px rgb(43 43 43);
  display: flex;
}



._table_th_td {
  border: 1px solid rgb(53, 53, 53);
  border-collapse: collapse;
}

._th_td {
  padding: 5px;
}


._th {
  background-color: rgb(53, 53, 53);
  color: #eee;
}

._table_tbody_tr:hover {
  background-color: rgb(163, 163, 163);
  cursor: pointer;
}

._table_input {
  width: 90%;
  height: 100%;
  border: 1px solid rgb(53, 53, 53);
  margin-top: auto;
  padding: 5px;
  background-color: #eee;
  color: black;
  font-size: 12pt;
}



.click-overlay{
  position: absolute;
  width: 100%;
  height: 100%;
  align-self: center;
  z-index: 1;
}

